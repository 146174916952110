@import "styles/variables";
.root {
    :global {
        svg {
            position: absolute;
            left: 12px;
            top: 10px;
            width: 100%;
            max-width: 20px;
        }

    .clientJobs {
        background: $bg-2;
    }
    }
}