@import "styles/variables";

.root {
    background: $base-white-color;

    :global {

        .logo {

            svg {
                width: 100%;
                height: auto;
                max-width: 250px;
            }

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5;
            }
        }

        .nav {
            margin: 0 -0.5rem;
        }

        .nav-item {
            padding: 0.3rem 0.7rem;

            a {
                color: $font-color;
                font-weight: 500;

                &.active {
                    color: $tertiary-color;
                }

                &:hover,
                &:focus,
                &:active {
                    color: $tertiary-color;
                }
            }
        }

        .jobseeker-nav {
            .nav-item {
                a {
                    &.active {
                        color: $secondary-color;
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        color: $secondary-color;
                    }
                }
            }

            .w-children {
                .dropdown {
                    .dropdown-item {
                        &:not(:last-child) {
                            border-bottom: 1px solid $secondary-color;
                        }
                    }

                    svg {
                        fill: $secondary-color;
                    }
                }
            }

        }

        .mobile-nav {
            background: $primary-color;
            padding: 0.7rem 0.8rem;
            cursor: pointer;
            z-index: 9;

            .line {
                width: 20px;
                height: 2px;
                background: $base-white-color;
                margin: 0.4rem 0;
                transition: all .3s ease;

            }

            &.active {
                .line {

                    &:nth-child(1) {
                        top: 5px;
                        @include transform(rotate(135deg));
                    }

                    &:nth-child(2) {
                        top: -3px;
                        @include transform(rotate(-135deg));
                    }
                }
            }
        }

        .logoWrapper {
            z-index: 9;
        }

        .mobile-nav-wrapper {
            position: fixed;
            z-index: 8;
            left: 0;
            right: 0;
            bottom: 0;
            top: -100%;
            height: 100%;
            transition: all .3s ease;
            background: rgba($base-white-color, 0.9);
            padding-top: 6rem;

            .accordion-header {
                .accordion-button {
                    padding: 0;
                    box-shadow: none;
                    background: transparent;
                }
            }

            .accordion-body {
                padding-right: 0;

                ul {
                    li {
                        &:not(:last-child) {
                            margin-bottom: 0.5rem;
                        }
                    }
                }
            }

            .nav {
                .nav-item {
                    width: 100%;

                    @include flex;
                    @include flex-wrap(wrap);
                    @include align-items(center);

                    &:not(:last-child) {
                        margin-bottom: 1rem;

                        >a {
                            padding-right: 1rem;

                        }
                    }
                }
            }

            &.active {
                top: 0;
            }
        }

        .dropdown-toggle {
            color: $font-color;
            font-weight: 500;
            background: transparent;
            border: none;
        }

        .dropdown-item {

            &:hover,
            &:focus,
            &:active {
                background: transparent;
            }
        }

        .w-children {
            .dropdown {
                position: initial;

                >button {
                    padding-right: 0rem;
                }

                button {
                    background: transparent;
                    border: none;
                }

                svg {
                    width: 20px;
                    height: 20px;
                    fill: $tertiary-color;
                }

                .dropdown-menu {
                    padding-bottom: 0;
                    box-shadow: 0 1rem 1rem rgba(0, 0, 0, .15);
                    left: 0 !important;
                    transform: translate3d(0px, 36px, 0px) !important;
                }

                .dropdown-item {

                    a {
                        min-width: 200px;
                        padding: 0.8rem 0.7rem;
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid $tertiary-color;
                    }
                }
            }

            &.active {
                box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
                border-top-left-radius: 0.5rem;
                border-top-right-radius: 0.5rem;

                .dropdown {
                    .dropdown-menu {
                        border-top-left-radius: 0;
                    }
                }
            }
        }

        @media (max-width: $breakpoint-sm) {
            .logo {
                svg {
                    max-width: 180px;
                }
            }
        }
    }
}