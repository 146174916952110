@import "styles/variables";

.root {
  background: $base-white-color;
  color: $base-white-color;




  :global {
    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      height: inherit !important;

      &[aria-hidden=true] {
        visibility: hidden;
      }

      > div {
        height: 100%;

        > div {
          height: 100%;
        }
      }
    }

    .slick-list {
      padding-top: 1.5rem;
    }

    .card {
      top: 0;
      @include trans;

      &:hover,
      &:focus,
      &:active {
        top: -20px;
      }
    }

    .secondary-bg-color {
      background: $secondary-color;
    }

    .primary-bg-color {
      background-color: $primary-color;
    }
    .arrows-wrapper {
      max-width: 70px;
    }

    .arrow {
      svg {
        cursor: pointer;
        width: 30px;
        height: 30px;
        @include trans;

        &:hover,
        &:focus,
        &:active {
          opacity: 0.5;
        }
      }

      &.prev {
        @include transform(rotate(-180deg));
      }
    }

    .tag {
      background: $base-white-color;
      color: $font-color;
    }

    .all-jobs-wrapper {
      margin: 0 -20px;

      .job-title {
        color: $font-color;
      }

      .job-salary-package {
        color: $font-color;
      }

      .job-location {
        color: $font-color;
      }

      .job-contract-type {
        color: $font-color;
      }
    }
  }
}
