@import "styles/variables";

.root {
    background: $base-white-color;
    color: $base-white-color;

    :global {
        a {
            color: $base-white-color;

            &:hover,
            &:focus,
            &:active {
                .card-img {
                    @include transform(scale(1.1));
                }
            }
        }

        .subtitle {
            &:after {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                background-image: url("/site-assets/svg/right-arrow.svg");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                width: 30px;
                height: 30px;
                filter: brightness(0) invert(1);

            }
        }

        .left {
            >div {
                background: $secondary-color;
            }

            .card-img {
                object-position: 25% 50%;
            }
        }

        .right {
            >div {
                background: $tertiary-color;
            }
        }

        .left,
        .right {
            >div {
                -webkit-mask-image: -webkit-radial-gradient(white, black);

            }

            a {
                padding-bottom: 80%;

            }
        }

        .content-wrapper {
            z-index: 1;
        }

        .card-img {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: all .5s ease-in-out;
            @include object-fit(cover);
        }

        .js-container {
            position: absolute;
            bottom: 1.5rem;
            left: 0;
            right: 0;
            z-index: 1;
            max-width: 92%;
            margin: 0 auto;
            overflow: hidden;
            height: auto;
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
            @include border-radius(7px);

            .js-total-count {
                margin: 0;
            }

            .js-columns-container {
                margin-top: 0;
            }

            .js-panel-border {
                padding: 0;
                border: none;
            }

            input {
                outline: none;
                width: 100%;
                border: none;
                padding: 1.8rem 6rem 1.8rem 1.8rem;
            }

            .js-button {
                position: absolute;
                right: 1rem;
                font-size: 0;
                padding: 1.8rem;
                border: none;
                background: $navy-blue;
                color: $base-white-color;
                transition: all 0.3s ease;
                @include border-radius(7px);
                top: 50%;
                @include transform(translateY(-50%));

                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 28px;
                    height: 28px;
                    transform: translate(-50%, -50%);
                    display: block;
                    background-image: url("/site-assets/svg/search.svg");
                    filter: brightness(0) invert(1);
                }

                &:hover,
                &:focus,
                &:active {
                    background: darken($navy-blue, 15%)
                }
            }
        }

        @media (max-width: $breakpoint-sm) {
            .right {
                .subtitle {
                    left: 8px;
                }
            }

            .js-container {
                input {
                    &::placeholder {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}