@import "styles/variables";

.root {
    background: $base-white-color;

    :global {

        .item-2 {
            .card-img {
                object-position: 0 -100px;
            }
        }

        .card-img {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 0.7;
            transition: all .5s ease-in-out;
            @include object-fit(cover);
        }

        .card {
            background: $primary-color;
            color: $base-white-color;
            -webkit-mask-image: -webkit-radial-gradient(white, black);
        }

        .post-title {
            font-weight: 400 !important;
        }

        a {

            &:hover,
            &:focus,
            &:active {
                .card-img {
                    filter: blur(3px);
                    @include transform(scale(1.1));
                }
            }
        }

        .left {
            .circle {
                right: 15%;
                bottom: -140px;
                width: 80px;
                height: 80px;
                background: $tertiary-color;
                @include border-radius(100px);
            }

            .card {
                padding-top: 70%;
            }
        }

        .right {
            .circle {
                right: 15%;
                top: 15%;
                width: 60px;
                height: 60px;
                background: $secondary-color;
                @include border-radius(100px);
            }

            .item-2 {
                a {
                    max-width: 100%;

                    .card {
                        padding-top: 60%;
                    }
                }
            }

            a {
                max-width: 70%;

                .card {
                    padding-top: 50%;

                }
            }
        }

        @media (max-width:$breakpoint-md) {
            .left {
                .card {
                    padding-top: 50%;
                }
            }

            .right {
                a {
                    &:nth-of-type(1) {
                        max-width: 100%;

                        .card {
                            padding-top: 50%;
                        }
                    }

                    &:nth-of-type(2) {
                        .card {
                            padding-top: 50%;
                        }
                    }
                }
            }

        }
    }

    &:global.all-blogs-page {
        .right {
            a {
                &:nth-of-type(1) {
                    max-width: 100%;

                    .card {
                        padding-top: 30%;
                    }
                }

                &:nth-of-type(2) {

                    .card {
                        padding-top: 30%;
                    }
                }
            }
        }

        .left {

            >div {
                height: 100%;
            }

            a {
                height: 100%;
            }

            .card {
                height: 100%;
            }
        }

        @media (min-width: $breakpoint-sm) {
            .left {
                padding-bottom: 1.5rem;
            }
        }
    }
}