@import "styles/variables";

.root {
    background: $base-white-color;

    :global {
        .content-wrapper {
            background: $bg-light;
        }

        a {
            &.link {
                color: $font-color;

                &:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: -5px;
                    background-image: url("/site-assets/svg/right-arrow.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    width: 35px;
                    height: 35px;
                }

            }
        }

        .icon {
            svg {
                width: 27px;
                height: 27px;

                path {
                    fill: $font-color;
                }
            }
        }

        hr {
            border-color: $bg-4;
        }

        @media (min-width: $breakpoint-sm) {
            hr {
                display: none;
            }
        }
    }
}